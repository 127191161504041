@use 'common'

$image-size: 2.5rem
$gap: 0.5rem

.wrapper
	display: flex
	gap: $gap

.image
	flex-shrink: 0
	position: relative
	width: $image-size
	height: $image-size
	border-radius: 50%
	font-size: 1.3em
	border: 1px solid #444444
	display: flex
	align-items: center
	justify-content: center
	overflow: hidden

.content
	position: relative
	width: 100%
	display: grid
	grid-template-columns: 100%
	font-size: 1rem

.name
	overflow: hidden
	text-overflow: ellipsis

.business
	//

.signOut
	margin-top: 0.75em
