@use 'common'

.wrapper
	//

.content
	font-size: 1em
	font-weight: 200
	display: grid
	gap: 0.25em 1em
	height: 100%
	width: 100%
	align-items: center

	@media (min-width: 25rem)
		grid-template-columns: auto 1fr

	@media (min-width: common.$breakMobile)
		grid-template-columns: 4fr 2fr 5fr

.payments
	display: flex
	gap: 0.5em

.price
	display: flex
	align-items: center
	gap: .3125rem
	font-size: 1.5625rem
	font-weight: 700

	@media (min-width: common.$breakMobile)
		text-align: right

.invoice
	text-align: right

	a
		text-decoration: underline

.state
	text-align: left

	@media (min-width: 25rem)
		text-align: right
