@use 'common'

.wrapper
	display: grid
	gap: common.$border-width
	grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr))
	border: common.$border-width solid common.$border-color
	border-radius: common.$tabs-border-radius
	overflow: hidden

.label
	display: block
	position: relative

	&::before
		position: absolute
		top: -1 * common.$border-width
		left: -1 * common.$border-width
		bottom: -1 * common.$border-width
		right: -1 * common.$border-width
		pointer-events: none
		content: ''
		border: common.$border-width solid common.$border-color

.content
	background-color: #EDEAE5
	text-align: center
	aspect-ratio: 1 / 1
	display: grid
	grid-template-rows: 1fr auto
	padding: 1.5em
	transition-property: background-color
	transition-duration: common.$transition-duration

.input
	position: absolute
	pointer-events: none
	overflow: hidden
	width: 1px
	height: 1px
	opacity: 0
	margin: 0

	&:checked + .content
		background-color: common.$tabs-active-color-light

.mark
	$size: 1.5em

	position: absolute
	width: $size
	height: $size
	background-color: common.$green-light
	border-radius: 50%
	top: 0.8em
	right: 1em
	transition-property: opacity
	transition-duration: common.$transition-duration
	opacity: 0

	&::before
		content: ''
		color: #ffffff
		border-bottom: 2px solid
		border-right: 2px solid
		width: 20%
		height: 40%
		position: absolute
		top: 24.5%
		right: 55%
		transform-origin: 100% 100%
		transform: rotate(45deg)

	.input:checked + .content &
		opacity: 1

.image
	position: relative

.text
	margin-top: 0.5em
	text-transform: uppercase
	font-size: 1.125em
	line-height: 1.1
