@use 'common'
@use 'sass:math'

.wrapper
	display: flex
	flex-direction: column
	justify-content: center
	text-align: center
	max-width: 40.9375rem
	min-height: 33vh
	margin: 0 auto

.title
	color: var(--global-colors-green-900)
	text-transform: uppercase
	font-size: 2.5rem
	font-weight: 700
	margin: 3rem 0

.description
	color: var(--global-colors-dark)
	font-weight: 700
	font-size: 1.0625rem
	line-height: math.div(22, 17)
	max-width: 80%
	margin: 0 auto
