@use 'common'

.wrapper
	position: relative
	z-index: 1
	background-color: #ffffff
	box-shadow: 0 0.5em 1.5em rgba(#000000, 0.07)
	border-radius: 1.25em
	padding: 1.19em 2em
	line-height: 1.1
	margin-top: 2em

	@media (min-width: common.$breakMobile)
		position: sticky
		bottom: 0.5rem

.in
	display: grid
	gap: 1em

	@media (min-width: common.$breakMobile)
		grid-template-columns: 1fr auto
		align-items: center

.summary
	font-size: 1.25em

.main,
.price
	@media (min-width: common.$breakMobile)
		font-size: 1.25em

.main
	font-weight: 700

.price
	font-weight: 700
	color: common.$goodlokRed

.note
	margin-top: 0.3em

.action
	display: grid
	gap: 0.5em

	@media (min-width: common.$breakMobile)
		grid-template-columns: auto auto
