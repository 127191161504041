@use 'common'

.wrapper
	margin: 2.5em 0 1.25em

.titleWrapper
	display: flex
	flex-wrap: wrap
	margin-top: 0 
	margin-bottom: 0.5em 
	gap: 0.25em 0.5em
	justify-content: space-between
	align-items: center

.title
	font-size: 1.5rem
	line-height: 1.2
	text-transform: uppercase
	font-weight: 300

.action
	//
