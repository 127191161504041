@use 'common'

$break: common.$breakMobile

.wrapper
	margin: 0

.item
	$image-maximum-width: 1.5em

	position: relative
	border: common.$border-width solid common.$border-color
	padding: 0.5em 1.5em
	display: grid
	gap: 0.8em
	grid-template-columns: 1fr

	@media (min-width: $break)
		grid-template-columns: 19rem 1fr

	&.is_withRecipeImage
		grid-template-columns: $image-maximum-width 1fr

		@media (min-width: $break)
			grid-template-columns: $image-maximum-width 19rem 1fr

	&:first-child
		border-top-left-radius: common.$tabs-border-radius
		border-top-right-radius: common.$tabs-border-radius

	&:last-child
		border-bottom-left-radius: common.$tabs-border-radius
		border-bottom-right-radius: common.$tabs-border-radius

	&:not(:first-child)
		margin-top: -1 * common.$border-width

	&.is_picked
		background-color: #d5edd6

	&:not(.is_picked):hover
		background-color: rgba(0, 0, 0, 0.03)

.quickPick
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	cursor: pointer

.image
	position: relative
	min-height: 3.5em

.about
	//

.title
	display: flex
	flex-wrap: wrap
	align-items: center
	gap: 0 0.5em

.error
	padding-bottom: .375rem

.name
	text-transform: uppercase
	margin: 0
	font-weight: 300
	font-size: 1.125em

.description
	margin-top: 0.2em
	font-size: 0.8125em
	font-weight: 200

.controls
	grid-column: span 2
	align-self: center
	display: flex
	flex-wrap: wrap
	align-items: center
	justify-content: flex-end
	gap: 0.5em

	@media (min-width: 25rem)
		gap: 1.5em

	@media (min-width: $break)
		align-items: flex-start
		grid-column: span 1

.quantity:not(.is_disabled),
.packing
	position: relative
	z-index: 1

.quantity.is_disabled
	position: relative
	z-index: -1

.quantity
	max-width: 6.25em

.packings
	display: flex
	flex-direction: column
	flex-grow: 1

	@media (min-width: common.$breakMobile)
		align-self: center
		flex-grow: 0

.packing
	$icon-size: 2em
	text-align: center
	position: relative
	padding: 0 1em 0 0
	display: flex
	align-items: center
	gap: 0em
	min-height: $icon-size

	@media (min-width: common.$breakMobile)
		min-width: 14rem
		padding: 0 1em

	&_detail
		display: flex
		align-items: center
		gap: 0.2em

	&_icon
		position: relative
		width: $icon-size
		height: $icon-size

	&_name
		font-size: 0.875em
		white-space: nowrap

	&_input
		position: absolute
		opacity: 0
		width: 1px
		height: 1px
		pointer-events: none

	&.is_disabled
		opacity: 0.5

.availability
	text-align: left
	font-size: 0.75em
	color: #FF0000
