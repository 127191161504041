@use 'common'

$line-width: 0.1em

.wrapper
	position: relative
	width: 1em
	height: 1em
	padding: 0
	font-size: inherit
	display: inline-flex
	flex-direction: column
	align-items: stretch
	justify-content: space-evenly

.line
	height: $line-width
	background-color: currentColor
