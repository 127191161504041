@use 'common'

.wrapper
	display: flex
	flex-direction: column
	font-size: 1.25em
	gap: 1em

.logo
	display: flex
	font-size: 1.35em
	vertical-align: top
	margin-bottom: 1.875rem

	@media (min-width: common.$breakMobile)
		margin-bottom: 3.75rem

.logoLabel
	color: common.$goodlokBlue
	font-weight: 700
	font-size: 1.125rem
	line-height: 1
	margin-top: -0.25rem
	margin-left: 2px

.primaryAction
	font-size: 1rem

.action
	text-transform: uppercase

.action,
.languageLink
	font-size: inherit
	font-family: inherit

	&:hover,
	&:focus
		text-decoration: underline

.language
	&.is_active
		font-weight: 700

.languageWrap
	&:not(:first-child)::before
		content: " / "

.userInfo
	display: flex
	flex-direction: column
	margin-top: auto
	gap: 1em

.signIn,
.signOut
	text-align: left
	padding: 0

.signIn,
.signOut
	&:hover
		text-decoration: underline
