@use 'common'

.wrapper
	display: grid
	grid-template-columns: 1fr 1fr
	gap: 0.9em 1.25em
	align-items: end

.item
	&.view_fullWidth
		grid-column: 1 / -1

	&.view_forceNextLine
		grid-column: 1
