@use 'common'

.wrapper
	font-style: normal
	background-color: common.$goodlokBgLight
	padding: common.$padding
	display: flex

.content
	flex-grow: 1

.name
	font-weight: 700

.buttons
	display: flex
	flex-wrap: wrap
	gap: 10px

.tags
	display: flex
	flex-direction: column
	gap: 5px
	align-items: flex-end

.tag
	border: 1px solid common.$goodlokDark
	color: common.$goodlokDark
	padding: 5px 10px
	border-radius: 5px
	font-size: 14px

.addressFormWrapper
	max-width: 100%
	width: common.$narrowPageWidth
