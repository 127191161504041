@use 'common'

$break: common.$breakDesktop
$transition-duration: 0.3s

.wrapper
	position: relative
	z-index: 0
	min-height: 100vh

	@media (min-width: $break)
		display: grid
		grid-template-columns: auto 1fr

.sideMenu
	position: fixed
	top: 0
	left: 0
	bottom: 0
	z-index: 2
	display: flex
	transform-property: transform, visibility
	transition-duration: $transition-duration
	transform: translateX(-100%)
	visibility: hidden

	.is_isSideMenuOpen &
		transform: none
		visibility: inherit

	@media (min-width: $break)
		position: sticky
		top: 0
		transform: none
		visibility: inherit
		align-self: start
		min-height: 100vh

	&_content
		background-color: #ffffff
		width: common.$sideMenu-width
		max-width: 100%
		padding: var(--global-page-horizontal-spacing)
		display: grid
		grid-template-rows: auto 1fr

		@media (min-width: $break)
			grid-template-rows: 1fr

	&_background
		position: fixed
		z-index: 1
		top: 0
		bottom: 0
		right: 0
		width: 100%
		height: 100%
		background-color: rgba(#000000, 0.1)
		cursor: auto
		transform-property: opacity, visibility
		transition-duration: $transition-duration
		opacity: 0
		visibility: hidden

		.is_isSideMenuOpen &
			opacity: 1
			visibility: inherit

		@media (min-width: $break)
			display: none

.closer,
.opener
	font-size: 2.5em
	display: flex
	margin-bottom: 0.5em

	@media (min-width: $break)
		display: none

.main
	width: 100%

	@media (min-width: $break)
		display: flex

		&::after
			content: ''
			max-width: common.$sideMenu-width
			flex-grow: 1

	&In
		padding: var(--global-page-horizontal-spacing)
		max-width: 72rem
		width: 100%
		margin: 0 auto
