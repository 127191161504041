@use 'common'

$line-width: 0.1em

.wrapper
	position: relative
	width: 1em
	height: 1em
	padding: 0
	font-size: inherit

	&::before,
	&::after
		position: absolute
		left: 0
		content: ''

	&::before
		top: calc(50% - #{0.5 * $line-width})
		height: $line-width
		left: $line-width
		right: 0
		background-color: currentColor

	&::after
		top: 50%
		width: 0.5em
		height: 0.5em
		border-left: $line-width solid
		border-top: $line-width solid
		transform: rotate(-45deg)
		transform-origin: 0 0
